import React, { Component } from 'react'
import Player from 'react-background-video-player'
import styled from 'styled-components'

import VideoSample from '../../video/background_v3.mp4'
import VideoPoster from '../../video/background_v3.jpg'

export default class Hero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isPlaying: undefined,
      isMuted: undefined,
      progress: 0,
      currentTime: 0,
      duration: 0,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    }
  }

  componentDidMount() {
    this.setState({
      isPlaying: !this.player.isPaused,
      isMuted: this.player.isMuted,
    })
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentWillMount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    })
  }

  handleOnPlay = () => {
    this.setState({ isPlaying: true })
  }

  handleOnPause = () => {
    this.setState({ isPlaying: false })
  }

  handleTimeUpdate = (currentTime, progress, duration) => {
    this.setState({
      progress,
      currentTime,
      duration,
    })
  }

  handleOnMute = () => {
    this.setState({ isMuted: true })
  }

  handleOnUnmute = () => {
    this.setState({ isMuted: false })
  }

  togglePlay = () => {
    this.player.togglePlay()
  }

  toggleMute = () => {
    this.player.toggleMute()
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '1000px',
          zIndex: '-1',
          left: '0px',
          top: '0px',
          opacity: '0.2',
        }}
      >
        <Player
          ref={p => (this.player = p)}
          containerWidth={this.state.windowWidth}
          containerHeight={this.state.windowHeight}
          src={VideoSample}
          poster={VideoPoster}
          onPlay={this.handleOnPlay}
          onPause={this.handleOnPause}
          onMute={this.handleOnMute}
          onUnmute={this.handleOnUnmute}
          onTimeUpdate={this.handleTimeUpdate}
          startTime={0}
          autoPlay={true}
          volume={0}
        />
        <Cover />
      </div>
    )
  }
}

const Cover = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100px;
  background: rgb(36, 36, 36);
  background: linear-gradient(
    0deg,
    rgba(36, 36, 36, 1) 10%,
    rgba(36, 36, 36, 0) 100%
  );
`
