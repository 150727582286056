import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'

export default ({ url }) => {
  return (
    <Video>
      <Flex flexWrap="nowrap">
        <Box width={[1, 8 / 12]} ml={[0, `${(2 / 12) * 100}%`]}>
          <PlayerWrapper>
            <Inner>
              <ReactPlayer
                className="react-player"
                url={url}
                width="100%"
                height="100%"
                controls={true}
                playing={false}
              />
            </Inner>
          </PlayerWrapper>
        </Box>
      </Flex>
    </Video>
  )
}

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 55.25%; /* Player ratio: 100 / (1280 / 720) */
`
const Video = styled.div`
  padding-top: 170px;
  padding-bottom: 0px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 100px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 3em;
    padding-bottom: 0em;
  }
`
