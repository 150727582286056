import React from 'react'
import { Link } from 'gatsby'
import { Title, ButtonLink } from '../page/components'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'

export default () => {
  return (
    <Footer>
      <Flex flexWrap="nowrap">
        <Box width={[1, 7 / 12]} ml={[1, `${(2 / 12) * 100}%`]}>
          <Title>Exclusively for Shillington students and graduates.</Title>
          <ButtonLink>
            <Link to="/sign-up">Sign Up</Link>
          </ButtonLink>
        </Box>
      </Flex>
    </Footer>
  )
}

const Footer = styled.div`
  padding-top: 170px;
  padding-bottom: 170px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 3em;
    padding-bottom: 3em;
  }
`
