import React from 'react'
import { Link } from 'gatsby'
import { Title, Logomark, ButtonLink } from '../page/components'
import { Flex, Box } from 'reflexbox/styled-components'
import styled from 'styled-components'

export default () => {
  return (
    <Header>
      <Flex flexWrap={['wrap', 'nowrap']}>
        <Box
          width={[0, 1 / 10]}
          mr={[0, `${(1 / 10) * 100}%`]}
          justifyContent="flex-end"
          display="flex"
        >
          <Logomark />
        </Box>
        <Box width={[1, 7 / 12]} pt={[4, 0]}>
          <Title>
            The graphic
            <br />
            design handbook,
            <br />
            reinvented
          </Title>
          <ButtonLink>
            <Link to="/sign-up">Sign Up</Link>
          </ButtonLink>
        </Box>
      </Flex>
    </Header>
  )
}

const Header = styled.div`
  padding-top: 170px;
  margin-bottom: 190px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 150px;
    margin-bottom: 100px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 90px;
    margin-bottom: 60px;
  }
`
