import React from 'react'
import { navigate } from 'gatsby'

import { AuthUserContext } from '../components/session'
import { Wrap } from '../components/page/components'
import Header from '../components/home/header'
import Footer from '../components/home/footer'
import Bricks from '../components/home/bricks'
import GlobalFooter from '../components/global/footer'
import GlobalHeader from '../components/global/header'
import SEO from '../components/SEO'
import Hero from '../components/home/hero'
import Video from '../components/home/video'

const videoUrl = 'https://vimeo.com/383170748'

export default () => {
  return (
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        authUser ? <HomeAuth authUser={authUser} /> : <HomeNonAuth />
      }
    </AuthUserContext.Consumer>
  )
}

const HomeAuth = () => {
  navigate('/graphic-design')
  return null
}

const HomeNonAuth = () => (
  <Wrap>
    <SEO title="Home" />
    <GlobalHeader hideLogo={true} />
    <Header />
    <Hero />
    <Bricks />
    <Video url={videoUrl} />
    <Footer />
    <GlobalFooter />
  </Wrap>
)
