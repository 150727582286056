import React from 'react'
import styled from 'styled-components'

// import IconEye from '../../components/svg/home/icon-eye'
import Brick1 from '../../images/home/brick-main.gif'
import Brick2 from '../../images/home/brick-shapey.gif'
import Brick4 from '../../images/home/brick-product-alt.jpg'
import Brick5 from '../../images/home/brick-brand.jpg'
import Brick3 from '../../images/home/brick-searching.gif'
import Brick6 from '../../images/home/brick-hustle.jpg'

export default () => {
  return (
    <Bricks>
      <Brick bg="#35BCAA" fg="greyDark">
        <Aspect>
          <Inner>
            <Copy>
              Over 20 years of content created and curated by Shillington's
              global team of passionate teachers
            </Copy>
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#262626">
        <Aspect>
          <Inner>
            <img src={Brick1} />
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#3A3A3A" fg="white">
        <Aspect>
          <Inner>
            <Copy>
              Get inspired by fascinating design case studies and industry
              examples
            </Copy>
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#777573">
        <Aspect>
          <Inner>
            <img src={Brick4} />
          </Inner>
        </Aspect>
      </Brick>

      <Brick bg="#262626">
        <Aspect>
          <Inner>
            <img src={Brick5} />
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#555" fg="white">
        <Aspect>
          <Inner>
            <Copy>
              Exclusively available to Shillington students and graduates
            </Copy>
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#777573">
        <Aspect>
          <Inner>
            <img src={Brick3} />
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#3A3A3A" fg="white">
        <Aspect>
          <Inner>
            <Copy>
              Searchable, portable, animated and environmentally
              friendly&mdash;right at your fingertips
            </Copy>
          </Inner>
        </Aspect>
      </Brick>

      <Brick bg="#35BCAA" fg="greyDark">
        <Aspect>
          <Inner>
            <Copy>
              Content evolving with the design industry to ensure you always
              have the most relevant information
            </Copy>
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#262626">
        <Aspect>
          <Inner>
            <img src={Brick2} />
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#3A3A3A" fg="white">
        <Aspect>
          <Inner>
            <Copy>
              All new Career Guide with industry insight to help you set goals
              and start your career
            </Copy>
          </Inner>
        </Aspect>
      </Brick>
      <Brick bg="#777573">
        <Aspect>
          <Inner>
            <img src={Brick6} />
          </Inner>
        </Aspect>
      </Brick>
    </Bricks>
  )
}

const Bricks = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Brick = styled.div`
  width: 25%;
  background: ${props => props.bg};
  color: ${props => props.theme.colors[props.fg ? props.fg : `greyDark`]};
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: 50%;
  }
`

const Aspect = styled.div`
  overflow: hidden;
  height: 0;
  padding-top: 100%;
  position: relative;
  img {
    max-width: 100%;
    height: auto;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

// const Icon = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 2;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
// `

const Copy = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 30px;
  ${props => props.theme.fontSizing(props.theme.font.brickSizes)};
  font-weight: bold;
  width: 90%;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding: 20px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding: 11px;
    width: 98%;
  }
`
